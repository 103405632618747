import React from 'react';

import Layout from '../components/layout';
import LinkButton from '../components/Button/link-button';
import HeroSideprojects from '../components/Hero/hero-sideprojects';
import SEO from '../components/seo';

import styles from './sideprojects.module.css';

import barfPlanPromo from '../images/barfplan-blog-promo@1x.png';
import barfPlanPromo2x from '../images/barfplan-blog-promo@2x.png';
import barfPlanPromo3x from '../images/barfplan-blog-promo@3x.png';
import barfPlanPromoWp from '../images/barfplan-blog-promo@1x.webp';
import barfPlanPromoWp2x from '../images/barfplan-blog-promo@2x.webp';
import barfPlanPromoWp3x from '../images/barfplan-blog-promo@3x.webp';

import barfPlanPromoSmall from '../images/barfplan-blog-promo-small@1x.png';
import barfPlanPromoSmall2x from '../images/barfplan-blog-promo-small@2x.png';
import barfPlanPromoSmall3x from '../images/barfplan-blog-promo-small@3x.png';
import barfPlanPromoWpSmall from '../images/barfplan-blog-promo-small@1x.webp';
import barfPlanPromoWpSmall2x from '../images/barfplan-blog-promo-small@2x.webp';
import barfPlanPromoWpSmall3x from '../images/barfplan-blog-promo-small@3x.webp';

import cnlPromo from '../images/checknlearn-promo@1x.jpg';
import cnlPromo2x from '../images/checknlearn-promo@2x.jpg';
import cnlPromo3x from '../images/checknlearn-promo@3x.jpg';
import cnlPromoWp from '../images/checknlearn-promo@1x.webp';
import cnlPromoWp2x from '../images/checknlearn-promo@2x.webp';
import cnlPromoWp3x from '../images/checknlearn-promo@3x.webp';

import cnlPromoSmall from '../images/checknlearn-promo-small@1x.jpg';
import cnlPromoSmall2x from '../images/checknlearn-promo-small@2x.jpg';
import cnlPromoSmall3x from '../images/checknlearn-promo-small@3x.jpg';
import cnlPromoWpSmall from '../images/checknlearn-promo-small@1x.webp';
import cnlPromoWpSmall2x from '../images/checknlearn-promo-small@2x.webp';
import cnlPromoWpSmall3x from '../images/checknlearn-promo-small@3x.webp';

import zsPromo from '../images/zaklepschronisko-promo@1x.jpg';
import zsPromo2x from '../images/zaklepschronisko-promo@2x.jpg';
import zsPromo3x from '../images/zaklepschronisko-promo@3x.jpg';
import zsPromoWp from '../images/zaklepschronisko-promo@1x.webp';
import zsPromoWp2x from '../images/zaklepschronisko-promo@2x.webp';
import zsPromoWp3x from '../images/zaklepschronisko-promo@3x.webp';

import ogImg from '../images/og/sideprojects.png';

const SideprojectsPage = () => (
  <Layout>
    <SEO
      title="Sideprojects"
      description="Working on a sideproject can give you a lot of benefits. This is a list of Tomek Dev's sideprojects."
      ogUrl="https://tomekdev.com/sideprojects"
      imageUrl={ogImg}
    />
    <HeroSideprojects />

    <div className="container">
      <section>
        <h2 className="pageTitle">BarfPlan</h2>

        <div className={styles.promo}>
          <picture>
            <source
              media="(max-width: 650px)"
              srcSet={`${barfPlanPromoSmall} 1x, ${barfPlanPromoSmall2x} 2x, ${barfPlanPromoSmall3x} 3x`}
            />
            <source
              media="(max-width: 650px)"
              srcSet={`${barfPlanPromoWpSmall} 1x, ${barfPlanPromoWpSmall2x} 2x, ${barfPlanPromoWpSmall3x} 3x`}
              type="image/webp"
            />
            <source
              media="(min-width: 651px)"
              srcSet={`${barfPlanPromoWp} 1x, ${barfPlanPromoWp2x} 2x, ${barfPlanPromoWp3x} 3x`}
              type="image/webp"
            />
            <img
              src={barfPlanPromo}
              srcSet={`${barfPlanPromo} 1x, ${barfPlanPromo2x} 2x, ${barfPlanPromo3x} 3x`}
              alt="BarfPlan - nutrition your dog deserves"
              width="950px"
              height="300px"
            />
          </picture>
        </div>

        <p>
          It's an application that I originally did for my wife and myself. We
          feed our dogs (two Red Irish Setters) with raw meat. This type of diet
          is usually called RAW or{' '}
          <abbr title="Biologically Appropriate Raw Food">BARF</abbr>. Dogs (or
          cats) eat raw meat, giblets, bones as well as some fruits and
          vegetables.
        </p>

        <p>
          Similar to humans' nutrition plans, it's not straightforward to keep a
          good diet where everything is properly balanced. Here, with raw meat,
          you should also give your pet supplements so they eat everything they
          should... and that makes it even harder. So the app is helping A LOT!
        </p>

        <p>
          The app has paying customers which makes me very proud and puts a bit
          of pressure on me to deliver more good stuff and invest more in
          marketing and customer service.
        </p>

        <div className={styles.buttonHolder}>
          <LinkButton
            href="https://getbarfplan.com/"
            target="_blank"
            rel="noreferrer"
          >
            Visit getbarfplan.com
          </LinkButton>
        </div>
      </section>

      <hr className={styles.line} />

      <section>
        <h2 className="pageTitle">checknlearn (discontinued)</h2>

        <div className={styles.promo}>
          <picture>
            <source
              media="(max-width: 650px)"
              srcSet={`${cnlPromoSmall} 1x, ${cnlPromoSmall2x} 2x, ${cnlPromoSmall3x} 3x`}
            />
            <source
              media="(max-width: 650px)"
              srcSet={`${cnlPromoWpSmall} 1x, ${cnlPromoWpSmall2x} 2x, ${cnlPromoWpSmall3x} 3x`}
              type="image/webp"
            />
            <source
              media="(min-width: 651px)"
              srcSet={`${cnlPromoWp} 1x, ${cnlPromoWp2x} 2x, ${cnlPromoWp3x} 3x`}
              type="image/webp"
            />
            <img
              src={cnlPromo}
              srcSet={`${cnlPromo} 1x, ${cnlPromo2x} 2x, ${cnlPromo3x} 3x`}
              alt="checknlearn motto"
              width="950px"
              height="300px"
            />
          </picture>
        </div>

        <p>
          It’s a service that{' '}
          <strong>helps you adopt new vocabulary much quicker</strong>. It kind
          of scratches my own itch but I hope other people will find it helpful
          as well.
        </p>

        <p>
          Whenever you encounter a new word when reading online you no longer
          have to open a new tab with a dictionary. With the checknlearn
          extension installed you just have to double click on a word and
          definition (or translation) will appear. Now,{' '}
          <strong>let’s face the truth</strong>, do you create flashcards for
          all new words you’ve „just learned”? No? So how you make sure you are
          increasing your vocabulary? Well, I’m lazy so at some point, I
          realized that sometimes I check the meaning of the same word.{' '}
          <strong>Checknlearn has you covered here.</strong> With a mobile app
          installed you will be reminded to practice words that you’ve gathered.
        </p>

        <h3>Project status - discontinued:</h3>

        <p>
          For now the project is discontinued. I focus my efforts on BarfPlan.
          Maybe I'll get back to this idea one day.
        </p>
      </section>

      <hr className={styles.line} />

      <section className="mt-75 mb-55">
        <h2 className="pageTitle">ZaklepSchronisko (closed)</h2>

        <div className={styles.promo}>
          <picture>
            <source
              srcSet={`${zsPromoWp} 1x, ${zsPromoWp2x} 2x, ${zsPromoWp3x} 3x`}
              type="image/webp"
            />
            <img
              src={zsPromo}
              srcSet={`${zsPromo} 1x, ${zsPromo2x} 2x, ${zsPromo3x} 3x`}
              alt="ZaklepSchronisko"
              width="950px"
              height="300px"
            />
          </picture>
        </div>

        <p>
          You can think of ZaklepSchronisko as a booking.com for mountain
          chalets. I hate descriptions like „Uber for X” but in this case, it’s
          quite ok to use it.{' '}
          <strong>
            You can make a reservation for mountain chalets in Poland.
          </strong>{' '}
          We plan to expand to other countries. Especially our neighbors - the
          Czech Republic and Slovakia.
        </p>

        <p>
          Together with Łukasz Prętki (co-founder), we realized that people
          owning mountain chalets hate booking.com. This service is not designed
          for them. The dynamics of mountain chalets, the standards, and the
          language is completely different. We understand that niche, although
          learning new things every day. That helps us adapt the product to
          people’s needs.
        </p>

        <p>
          <strong>Free Polish lesson:</strong> „Zaklep” „Schronisko” means
          something like „Dibs on chalet”.
        </p>

        <h3>Project status - closed:</h3>

        <p>
          At this point the project is closed. We were struggling to get
          traction. After all, the service we wanted to provide is a platform.
          Platform connects group A with group B. We were able to easily attract
          one group (tourists) but not the other (shelters).
        </p>

        <p>
          I definitely have a lot of insights and learnings from this project. I
          also have an idea how to reactive this but that's unlikely to happen
          due to my commitment to other projects.
        </p>
      </section>

      <div className={styles.heroAttribution}>
        Photo in the cover by{' '}
        <a
          href="https://unsplash.com/@wasdrew"
          target="_blank"
          rel="noreferrer"
        >
          Andras Vas
        </a>{' '}
        on Unsplash
      </div>
    </div>
  </Layout>
);

export default SideprojectsPage;
