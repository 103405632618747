import React from 'react';
import PropTypes from 'prop-types';

import styles from './button.module.css';

const LinkButton = ({ children, href, rel, target }) => (
  <a href={href} target={target} rel={rel} className={styles.button}>
    {children}
  </a>
);

LinkButton.propTypes = {
  children: PropTypes.node.isRequired,
  href: PropTypes.string.isRequired,
  rel: PropTypes.string,
  target: PropTypes.string,
};

export default LinkButton;
