import React from 'react';

import styles from './hero-sideprojects.module.css';

const HeroSideprojects = () => (
  <div className={styles.hero}>
    <div>
      <h1 className={styles.heroName}>Sideprojects</h1>
    </div>
  </div>
);

export default HeroSideprojects;
